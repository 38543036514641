import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const DeZhuang = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/dezhuang/dezhuang-1.png`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/dezhuang/dezhuang-2.jpeg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/dezhuang/dezhuang-3.jpeg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "De Zhuang Hotpot Central Gurney",
        "De Zhuang Hotpot Central Gurney",
        "De Zhuang Hotpot Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Chili's" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">De Zhuang Hotpot</h2>
                        <p className="font-light text-gray-600 text-center">Sun – Thurs: 12pm-10:30pm <br/> Fri - Sat: 12pm -11:30pm</p>
                        {/* <p className="font-light text-gray-600">For enquiries: 04-2170685</p>  */}
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                        Originating from Chongqing, China, De Zhuang Hotpot has delighted food lovers worldwide with its unique spicy flavours since 1999. With over 1,000 locations globally, it’s now a star in the hotpot industry. They are excited to announce the grand opening of their second Malaysian restaurant, bringing authentic Chongqing flavours and the signature "Li's Spiciness Level" to more food enthusiasts. This secret recipe precisely categorizes spiciness, ensuring a perfect match for every palate, from spice connoisseurs to first-timers. At De Zhuang Hotpot, they continue to use premium ingredients and modern techniques to deliver an exceptional dining experience tailored for Malaysian food lovers.
                        </p>
                        {/* <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-5-kanada.jpg`} {...imageProps}/>
                        </div> */}
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default DeZhuang
